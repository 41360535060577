import { useAxios } from '@/app/composable';
import { AssetsAPI } from '@/modules/asset/api';
import { ScheduleAPI, TaskAPI } from '@/modules/workflow-designer/api';
import { Ref, computed, ref } from '@vue/composition-api';
import { clone, equals, has, isEmpty, isNil, last, omit, pluck } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { ApolloAPI } from '../api';
import {
    HarvesterBlockId,
    PreprocessingBlockId,
    TaskExecutionStatus,
    TaskStatus,
    TaskType,
    WorkflowStatus,
    blockIdToTaskMap,
} from '../constants';
import {
    ApolloTask,
    ApolloTaskConfiguration,
    FieldConfiguration,
    HarvesterConfiguration,
    MappingConfiguration,
} from '../types';
import { CleaningFieldConfiguration, Condition, Constraint } from '../types/cleaning.type';
import { FieldConfiguration as CompleteFieldConfiguratton } from '../types/typings';
import { useSampleFields } from './sample-fields';

export function useApolloTask<T extends ApolloTaskConfiguration>(task: Ref<ApolloTask<T> | undefined>) {
    const { loading, exec } = useAxios(true);

    const structure: Ref<Record<string, string>> = ref<Record<string, string>>({});

    const taskStructure: Ref<Record<string, string>> = computed(() => structure.value);

    const pipelineId: Ref<string | undefined> = computed(() => task.value?.pipeline.id);

    const taskType: Ref<TaskType | undefined> = computed(() =>
        task.value && blockIdToTaskMap[task.value.blockId] ? blockIdToTaskMap[task.value.blockId] : undefined,
    );

    const inDraftStatus = computed(() => task.value?.status === TaskStatus.Draft);

    const isRunning = computed<boolean>(
        () => !!task.value && task.value.executionStatus === TaskExecutionStatus.Running,
    );

    const isFinalized = computed<boolean>(
        () => !!task.value && ![TaskStatus.Draft, TaskStatus.Updating].includes(task.value.status),
    );

    const inUpdateStatus = computed(() => task.value?.status === TaskStatus.Updating);

    const inDeprecatedStatus = computed<boolean>(() => task?.value?.status === TaskStatus.Deprecated);

    const hasFailed = computed(() => task.value?.executionStatus === TaskExecutionStatus.Failed);

    const hasCompleted = computed(() => task.value?.executionStatus === TaskExecutionStatus.Completed);

    const canRevise = computed(() => hasFailed.value && !!task.value && task.value.canEdit);

    const sampleRunExecuted = computed(() => task.value?.sampleRunExecuted);

    const isStreaming = computed(
        () =>
            !!task.value &&
            [
                HarvesterBlockId.Kafka,
                HarvesterBlockId.ExternalKafka,
                HarvesterBlockId.MQTT,
                HarvesterBlockId.ExternalMQTT,
            ].includes(task.value.blockId as HarvesterBlockId),
    );

    const pipelineFinalized = computed(() => task.value?.pipeline.status === WorkflowStatus.Ready);

    const refetch = (): Promise<ApolloTask<T>> => {
        return new Promise((resolve, reject) => {
            if (!pipelineId.value || !taskType.value) throw Error('Pipeline not defined');
            exec(ApolloAPI.getTask(pipelineId.value, taskType.value))
                .then((res) => {
                    task.value = res?.data;
                    resolve(res?.data);
                })
                .catch((e) => reject(e));
        });
    };

    const isConfigEmpty = (config: HarvesterConfiguration): boolean =>
        config === null || isEmpty(omit(['files', 'scheduler'], config));

    const { extractMappingFieldNames } = useSampleFields();

    const detailsAreValid = (
        details: { field?: string | null; conditions?: { field?: string | null; logicalOperator?: string | null }[] },
        removedFields: string[],
    ) => {
        // remove constraints that have field in details which is removed
        if (details.field && removedFields.includes(details.field)) return false;

        if (details.conditions) {
            // remove any conditions that use a removed field
            details.conditions = details.conditions.filter((c) => !c.field || !removedFields.includes(c.field));
            // remove constraints that have no conditions
            if (details.conditions.length === 0) return false;
            // remove logical operator if only one condition
            if (details.conditions.length === 1 && details.conditions[0].logicalOperator)
                details.conditions[0].logicalOperator = null;
        }

        return true;
    };

    const conditionIsValid = (condition: Condition, removedFields: string[]) => {
        // remove constraints that have fieldName which is removed
        if (condition.fieldName && removedFields.includes(condition.fieldName)) return false;

        // remove constraints that have invalid details
        if (condition.details && !detailsAreValid(condition.details, removedFields)) return false;

        if (condition.conditions) {
            // remove any conditions that use a removed field
            condition.conditions = filterCleaningConditions(condition.conditions, removedFields);
            // remove constraints that have no conditions
            if (condition.conditions.length === 0) return false;
        }
        return true;
    };

    const filterCleaningConditions = (conditions: Condition[], removedFields: string[]) =>
        conditions.reduce((acc: Condition[], condition: Condition) => {
            if (conditionIsValid(condition, removedFields)) acc.push(condition);
            return acc;
        }, []);

    const removeFieldsFromConstraints = (field: CleaningFieldConfiguration, removedFields: string[]) => {
        field.constraints = field.constraints.reduce((acc: Constraint[], constraint: Constraint) => {
            // check simple cleaning rules
            if (constraint.details && detailsAreValid(constraint.details, removedFields)) acc.push(constraint);

            // check complex cleaning rules
            if (constraint.structure) {
                // remove any conditions that use a removed field
                constraint.structure.conditions = filterCleaningConditions(
                    constraint.structure.conditions,
                    removedFields,
                );
                // keep constraints that have conditions
                if (constraint.structure.conditions.length > 0) acc.push(constraint);
            }
            return acc;
        }, []);
        return field;
    };

    /**
     * @param mappingFields fields configured in mapping
     * @param fieldsInTask fields configured in current task
     * @returns revised fields in current task
     */
    const reviseFields = (mappingFields: FieldConfiguration[], fieldsInTask: CompleteFieldConfiguratton[]) => {
        const extractedFieldsFromMapping = extractMappingFieldNames(mappingFields);

        const findField = (
            field: { id: number; parentIds: number[] },
            fields: { id: number; parentIds: number[]; modified?: any }[],
        ) => fields.find((field2) => equals([...field.parentIds, field.id], [...field2.parentIds, field2.id]));

        const deletedFields = pluck(
            'name',
            fieldsInTask.filter((field) => !findField(field, extractedFieldsFromMapping)),
        );

        return extractedFieldsFromMapping.map((fieldInMapping) => {
            let fieldInCurrentTask: any = findField(fieldInMapping, fieldsInTask);

            if (fieldInCurrentTask) {
                // mark modified fields
                fieldInCurrentTask.modified = fieldInMapping.modified;

                // remove conditions in advanced rules that use any deleted fields from mapping
                if (task.value?.blockId === PreprocessingBlockId.Cleaning) {
                    const originalField = clone(fieldInCurrentTask);
                    fieldInCurrentTask = removeFieldsFromConstraints(fieldInCurrentTask, deletedFields);
                    if (!equals(originalField, fieldInCurrentTask)) fieldInCurrentTask.modified = true;
                }
            } else {
                // add new field
                fieldInCurrentTask = initialiseNewField(fieldInMapping);
            }
            return fieldInCurrentTask;
        });
    };

    const initialiseNewField = (fieldInMapping: any) => {
        switch (task.value?.blockId) {
            case PreprocessingBlockId.Cleaning:
                return { ...fieldInMapping, constraints: [] };
            case PreprocessingBlockId.Encryption:
                return { ...fieldInMapping, index: false };
            case PreprocessingBlockId.Anonymisation:
                return {
                    id: fieldInMapping.id,
                    type: fieldInMapping.type,
                    name: fieldInMapping.name,
                    originalName: fieldInMapping.originalName,
                    anonymisationIdentifier: uuidv4(),
                    anonymisationType: 'insensitive',
                };
            default:
                return fieldInMapping;
        }
    };

    const shouldUpdateAssetsAfterRevise = () => {
        return (
            task.value?.status === TaskStatus.Updating &&
            task.value.nextTask?.displayName === 'Loader' &&
            task.value.nextTask?.status !== TaskStatus.Draft
        );
    };

    const updateAssetAfterRevise = async (assetId: number): Promise<void> => {
        if (isNil(pipelineId.value)) throw new Error('Pipeline not defined');
        const schedules = (await ScheduleAPI.getSchedules(pipelineId.value as string))?.data;

        return new Promise((resolve, reject) => {
            exec(ApolloAPI.get(pipelineId.value as string)).then((res: any) => {
                const pipeline = res?.data;
                const preprocessingTasks = pipeline.tasks.map((ptask: ApolloTask) => ({
                    blockId: ptask.blockId,
                    type: ptask.displayName.toLowerCase(),
                    configuration: ptask.configuration,
                }));
                exec(AssetsAPI.updateAssetAfterFailedTask(assetId, { schedules, preprocessingTasks }))
                    .then((resAsset: any) => {
                        const asset = resAsset?.data;
                        const loader = preprocessingTasks.find((ptask: { type: string }) => ptask.type === 'loader');
                        if (!pipelineId.value) reject('Pipeline not defined');
                        exec(
                            ApolloAPI.updateTask(pipelineId.value as string, 'loader', {
                                ...loader.configuration,
                                collection: assetId,
                                structure: asset.structure,
                            }),
                        )
                            .then(() => {
                                resolve();
                            })
                            .catch((e) => reject(e));
                    })
                    .catch((e) => reject(e));
            });
        });
    };

    const updateAssetsAfterRevise = async () =>
        Promise.all(task.value?.pipeline.assetIds.map((assetId) => updateAssetAfterRevise(assetId)) ?? []);

    const save = async (clearProcessedSample = false): Promise<ApolloTask<T>> =>
        new Promise((resolve, reject) => {
            if (!pipelineId.value || !taskType.value || !task.value) throw Error('Pipeline not defined');
            exec(
                ApolloAPI.updateTask(pipelineId.value, taskType.value, task.value?.configuration, clearProcessedSample),
            )
                .then((res: any) => {
                    task.value = res?.data;
                    resolve(res.data);
                })
                .catch((e) => reject(e));
        });

    const finalize = async (): Promise<ApolloTask<T>> =>
        new Promise((resolve, reject) => {
            if (!pipelineId.value || !taskType.value) throw Error('Pipeline not defined');

            exec(ApolloAPI.finalizeTask(pipelineId.value, taskType.value))
                .then((res: any) => {
                    task.value = res?.data;
                    resolve(res.data);
                })
                .catch((e) => reject(e));
        });

    /**
     * if harvester field selection of a cloned pipeline changes, then we should clear the cloned processed sample
     * @returns
     */
    const shouldClearHarvesterProcessedSample = async () => {
        if (!task.value || taskType.value !== 'harvester' || !inUpdateStatus.value) return false;

        const { data: mapping } = (await exec(ApolloAPI.getTask(pipelineId.value!, 'mapping'))) as {
            data: ApolloTask<MappingConfiguration>;
        };
        const selectedFields: { path: string[]; title: string }[] = [];

        if (has('response', task.value?.configuration)) {
            if (has('selectedItems', task.value.configuration.response)) {
                selectedFields.push(
                    ...task.value.configuration.response.selectedItems
                        .map((item) => item.replaceAll('[0]', '[]').split('||').slice(1)) // remove initial "res"
                        .map((item) => ({ path: item.slice(0, -1), title: last(item) as string })),
                );
            }
            if (has('additional', task.value.configuration.response)) {
                selectedFields.push(
                    ...task.value.configuration.response.additional?.map((additional) => ({
                        path: [],
                        title: additional.key,
                    })),
                );
            }
        }

        const { fieldIsMapped } = useSampleFields();

        // clear processed sample if there is at least one mapped field which we have not selected
        return (
            mapping.configuration.fields
                .filter(fieldIsMapped)
                .some(
                    (field) =>
                        !selectedFields.some(
                            (selectedField) =>
                                equals(selectedField.path, field.source.path) &&
                                selectedField.title === field.source.title,
                        ),
                ) ?? false
        );
    };

    const fetchStructure = async () => {
        new Promise((resolve, reject) => {
            if (!task.value) throw Error('Task not defined');

            exec(TaskAPI.resultStructure(task.value.id))
                .then((res: any) => {
                    structure.value = res?.data;
                    resolve(res.data);
                })
                .catch((e) => reject(e));
        });
    };

    return {
        loading,
        inDraftStatus,
        inDeprecatedStatus,
        isFinalized,
        inUpdateStatus,
        isRunning,
        hasFailed,
        hasCompleted,
        canRevise,
        isStreaming,
        pipelineFinalized,
        sampleRunExecuted,
        taskStructure,
        save,
        finalize,
        refetch,
        isConfigEmpty,
        updateAssetsAfterRevise,
        shouldUpdateAssetsAfterRevise,
        reviseFields,
        initialiseNewField,
        shouldClearHarvesterProcessedSample,
        fetchStructure,
    };
}
